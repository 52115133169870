import React from "react";
import Footer from "./Shared/Footer/Footer";
import NewNav from "./Shared/Navbar/NewNav";
import styled from "styled-components";

const Layout = ({component}) => {
    return (
        <>
            <header>
                <NewNav />
                {/* <Navbar /> */}
            </header>
            <LayoutComponents>
                {
                    component
                }
            </LayoutComponents>
            <footer>
                <Footer />
            </footer>
        </>
    );
}

const LayoutComponents = styled.div`
    padding-top: 96px;
    @media screen and (max-width: 1200px){
        padding-top: 65px;
    }
`;
export default Layout;

