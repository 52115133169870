import React, {useState} from 'react'
import {navigate, Link} from 'gatsby';

import { DrvInput } from '../DrvInput/DrvInput';
import { AiOutlineArrowRight } from 'react-icons/ai'
import { Fade as Hamburger } from 'hamburger-react'
import { DrvSearchIcon } from '../DrvSearchIcon/DrvSearchIcon';
import { NavBarContainer, Nav, Logo, NavItems ,NavItem, NavDropdown, Bars, ResponsiveNavItems, ResponsiveNavItem, ResponsiveSearchBar, ResponsiveNavDropdown, SearchDiv } from './NewNav.styles';

import DrivosityLogo from "../../../images/Logo.png";

const NewNav = () =>{
    const [responsiveNav, setResponsiveNav] = useState(false);
    const [responsiveSolution, setResponsiveSolution] = useState(false);
    const [responsiveOurCompany, setResponsiveOurCompany] = useState(false);
    const [searchInput, setSearchInput] = useState('')
    const [dropdown, setDropdown] = useState({
        solutionDropdown: false,
        ourCompanyDropdown: false,
        search: false
    })

    const responsiveNavHandler = () => setResponsiveNav(!responsiveNav);

    const onMouseEnter = (event) => {
        let target = event.target.getAttribute('name')
        if (window.innerWidth < 960) {
            setDropdown({
                [target] : false
            })
            // setSolutionDropdown(false);
        } else {
            setDropdown({
                [target] : true
            })
            // setSolutionDropdown(true);
        }
    };

    const onMouseLeave = (event) => {
        let target = event.target.getAttribute('name')
        if (window.innerWidth < 960) {
            setDropdown({
                [target] : false
            })
            // setSolutionDropdown(false);
        } else {
            setDropdown({
                [target] : false
            })
            // setSolutionDropdown(false);
        }
    };

    const searchWithEnter = (event) => {
        setSearchInput(event.target.value);

        if(event.keyCode === 13){
            navigate(`/search?s=${searchInput}&p=1`);
        }
    };

    const searchButton = () =>{
        navigate(`/search?s=${searchInput}&p=1`);
    }

    const solutions = [
        { name: 'Safety', route: '/safety', active: false },
        { name: 'Productivity', route: '/productivity', active: false },
        { name: 'Financing', route: '/financing', active: false },
        { name: 'Insurance', route: '/insurance', active: false }
    ];

    const ourCompany = [
        { name: 'About Us', route: '/about-us', active: false },
        { name: 'For Business', route: '/for-business', active: false },
        { name: 'For Drivers', route: '/for-drivers', active: false },
        { name: 'Drivosity and HTH', route: '/drivosity-and-hth', active: false },
        { name: 'Drivosity Certified', route: '/drivosity-certified', active: false },
        { name: 'News', route: '/news', active: false },
        { name: 'Contact Us', route: '/contact-us', active: false }
    ];
    return(
        <NavBarContainer>
            <Nav className="container">
                <Link to="/">
                    <Logo src={DrivosityLogo}/>
                </Link>
                <Bars>
                    <Hamburger onToggle={responsiveNavHandler} />
                </Bars>
                <NavItems>
                    <NavItem
                        name="solutionDropdown"
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}>
                        SOLUTIONS
                        {dropdown['solutionDropdown'] && (
                            <NavDropdown>
                                {
                                    solutions.map((item) =>(
                                        <Link to={item.route} key={item.name}>{item.name}</Link>
                                    ))
                                }
                            </NavDropdown>
                        )}
                    </NavItem>
                    <NavItem
                        name="ourCompanyDropdown"
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        >
                        OUR COMPANY
                        {dropdown['ourCompanyDropdown'] && (
                            <NavDropdown>
                                {
                                    ourCompany.map((item) =>(
                                        <Link to={item.route} key={item.name}>{item.name}</Link>
                                    ))
                                }
                            </NavDropdown>
                        )}
                    </NavItem>
                    <NavItem>
                        <Link to="/careers">
                            CAREERS
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link to="/customer-stories">
                            CUSTOMER STORIES
                        </Link>
                    </NavItem>
                    <SearchDiv
                        name="search"
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}>
                        {/* <FiSearch className="w-[20px] h-[20px]" /> */}
                        <DrvSearchIcon active={dropdown['search']} color="#FF0000" height="40px" width="40px" />
                        {
                            dropdown['search'] && (
                                <NavDropdown className="searchDropdown" style={{zIndex:'999999999999999999'}}>
                                    <DrvInput type="text" placeholder="Search" name="searchInput" value={searchInput} className="searchInput" onChange={(event) => {setSearchInput(event.target.value)}} onKeyUp={searchWithEnter} autocomplete="off"/>
                                    <div className="absolute right-[45px] top-[39px]" style={{color: 'var(--primary-color)'}} onClick={searchButton} onKeyUp="if (event.keyCode === 13) searchButton()" tabIndex="0" role='button'>
                                        <AiOutlineArrowRight style={{fontSize: "17px"}} className="ml-3" />
                                    </div>
                                </NavDropdown>
                            )
                        }
                    </SearchDiv>
                </NavItems>
                {
                    responsiveNav && (
                        <ResponsiveNavItems>
                            <ResponsiveNavItem to="#" onClick={() => setResponsiveSolution(!responsiveSolution)} >
                                SOLUTIONS
                            </ResponsiveNavItem>
                            {
                                <ResponsiveNavDropdown maxHeight={152} className={responsiveSolution ? 'open' : 'close'}>
                                    {
                                        solutions.map((item) =>(
                                            <Link to={item.route} key={item.name}>{item.name}</Link>
                                        ))
                                    }
                                </ResponsiveNavDropdown>
                            }
                            <ResponsiveNavItem to="#" onClick={() => setResponsiveOurCompany(!responsiveOurCompany)}>
                                OUR COMPANY
                            </ResponsiveNavItem>
                                {
                                    <ResponsiveNavDropdown maxHeight={354} className={responsiveOurCompany ? 'open' : 'close'}>
                                        {
                                            ourCompany.map((item) =>(
                                                <Link to={item.route} key={item.name}>{item.name}</Link>
                                            ))
                                        }
                                    </ResponsiveNavDropdown>
                                }
                            <ResponsiveNavItem to="/careers">
                                CAREERS
                            </ResponsiveNavItem>
                            <ResponsiveNavItem to="/customer-stories">
                                CUSTOMER STORIES
                            </ResponsiveNavItem>
                            <ResponsiveSearchBar>
                                {/* <FiSearch className="w-[20px] h-[20px] " /> */}
                                <DrvSearchIcon responsive="true" height="35px" width="35px" />
                                <DrvInput type="text" placeholder="Search" name="searchInput" value={searchInput} className="searchInput" onChange={(event) => {setSearchInput(event.target.value)}} onKeyUp={searchWithEnter}/>
                                    <div className="absolute left-[360px] top-[22px]" style={{color: 'var(--primary-color)', cursor:'pointer'}} onClick={searchButton} onKeyUp="if (event.keyCode === 13) searchButton()" tabIndex="0" role='button'>
                                        <AiOutlineArrowRight style={{fontSize: "17px"}} className="ml-3" />

                                    </div>
                            </ResponsiveSearchBar>
                        </ResponsiveNavItems>
                    )
                }
            </Nav>
        </NavBarContainer>
    )
}


export default NewNav;
