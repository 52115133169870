import styled from "styled-components";
import {GrFacebookOption, GrTwitter, GrInstagram, GrLinkedinOption, GrYoutube} from 'react-icons/gr';
import {Link} from "gatsby";

export const Container = styled.div`
  background-color: #545454;
  color: #ffffffab;
`;
export const FooterContainer = styled.div`
`;
export const UpperSection = styled.div`
  border-bottom: 1px solid;
  padding: 20px 0;
  font-size: 13px;
`;
export const DownSection = styled.div`
    padding: 20px 0;
`;
export const DrvLogo = styled.img`
    width: 200px;
    margin-top: 15px;
    margin-bottom: 20px;
`;
export const FooterLink = styled(Link)``;
export const SocilaMedia = styled.div``;
export const FacebookIcon = styled(GrFacebookOption)`
  width: 30px;
  height: 30px;
  display: flex;
  background-color: #ffffffab;
  color: #545454;
  margin-left: 5px;
  padding: 5px;
  border-radius: 50%;
  overflow: visible;
  transition: 0.4s ease;
  &:hover{
    color: #fff;
    background-color: #4267B2;
  }
`;
export const InstaIcon = styled(GrInstagram)`
  width: 30px;
  height: 30px;
  display: flex;
  background-color: #ffffffab;
  color: #545454;
  margin-left: 5px;
  padding: 5px;
  border-radius: 50%;
  overflow: visible;
  transition: 0.4s ease;
  &:hover{
    color: #fff;
    background-color: #C13584;
  }
`;
export const TwitterIcon = styled(GrTwitter)`
  width: 30px;
  height: 30px;
  display: flex;
  background-color: #ffffffab;
  color: #545454;
  margin-left: 5px;
  padding: 5px;
  border-radius: 50%;
  overflow: visible;
  transition: 0.4s ease;
  &:hover{
    color: #fff;
    background-color: #00acee;
  }
`;
export const LinkedinIcon = styled(GrLinkedinOption)`
  width: 30px;
  height: 30px;
  display: flex;
  background-color: #ffffffab;
  color: #545454;
  margin-left: 5px;
  padding: 5px;
  border-radius: 50%;
  overflow: visible;
  transition: 0.4s ease;
  &:hover{
    color: #fff;
    background-color: #0077b5;
  }
`;
export const YouTubeIcon = styled(GrYoutube)`
  width: 30px;
  height: 30px;
  display: flex;
  background-color: #ffffffab;
  color: #545454;
  margin-left: 5px;
  padding: 5px;
  border-radius: 50%;
  overflow: visible;
  transition: 0.4s ease;
  &:hover{
    color: #fff;
    background-color: #ff0001;
  }
`;
export const Copyright = styled.div`
  font-size: 13px;
`;


