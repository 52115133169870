import React from 'react';
import './DrvSearchIcon.css'
export const DrvSearchIcon = (props) => {
    if(!props.responsive){
        return(
            <svg version="1.1" className={props.active ? 'active' : 'inactive'} height={props.height} width={props.width} id="Layer_1" x="0px" y="0px"viewBox="0 0 1000 793" style={{enableBackground: "new 0 0 1000 793"}}>
                <g>
                    <g>
                        <circle className="st0" cx="501.8" cy="483.6" r="200.7"/>
                        <polygon className="st0" points="999.3,40.5 998.1,3.1 727.5,146.3 504.1,-0.1 503.8,0.9 503.1,-0.1 279.3,151.9 3,6.7 3,8 0.8,6.7 
                            0.8,631.9 0,647.8 0.8,648.2 0.8,656.4 16.4,656.4 275,792.4 275.2,792 275.5,792.4 377.6,728.4 325,696 307.6,706.9 307.6,653.9 
                            251.1,601.4 251.1,717.2 56.1,614.8 56.1,97.1 251.1,199.6 251.1,384.3 305.8,320.1 305.8,200.8 504.1,66.1 698.7,193.6 
                            694.5,193.6 694.5,319.7 749.3,380.6 749.3,197.3 944.6,94 944.6,622.3 839.7,674.8 888.5,712.3 1000,656.5 999.9,656.4 
                            1000,656.4 1000,40.2 		"/>
                        <path className="st0" d="M793.6,776.7l-4.7,4.4c-17.7,16.7-45.6,15.8-62.3-1.9L603.8,648.5c-16.7-17.7-15.8-45.6,1.9-62.3l4.7-4.4
                            c17.7-16.7,45.6-15.8,62.3,1.9l122.8,130.7C812.2,732.2,811.3,760.1,793.6,776.7z"/>
                    </g>
                    <circle className="st1" cx="501.8" cy="483.6" r="142.7"/>
                </g>
            </svg>
        )
    }
    else{
        return(
            <svg version="1.1" className="responsieSearch" height={props.height} width={props.width} id="Layer_1" x="0px" y="0px"viewBox="0 0 1000 793" style={{enableBackground: "new 0 0 1000 793"}}>
                <g>
                    <g>
                        <circle className="st0" cx="501.8" cy="483.6" r="200.7"/>
                        <polygon className="st0" points="999.3,40.5 998.1,3.1 727.5,146.3 504.1,-0.1 503.8,0.9 503.1,-0.1 279.3,151.9 3,6.7 3,8 0.8,6.7 
                            0.8,631.9 0,647.8 0.8,648.2 0.8,656.4 16.4,656.4 275,792.4 275.2,792 275.5,792.4 377.6,728.4 325,696 307.6,706.9 307.6,653.9 
                            251.1,601.4 251.1,717.2 56.1,614.8 56.1,97.1 251.1,199.6 251.1,384.3 305.8,320.1 305.8,200.8 504.1,66.1 698.7,193.6 
                            694.5,193.6 694.5,319.7 749.3,380.6 749.3,197.3 944.6,94 944.6,622.3 839.7,674.8 888.5,712.3 1000,656.5 999.9,656.4 
                            1000,656.4 1000,40.2 		"/>
                        <path className="st0" d="M793.6,776.7l-4.7,4.4c-17.7,16.7-45.6,15.8-62.3-1.9L603.8,648.5c-16.7-17.7-15.8-45.6,1.9-62.3l4.7-4.4
                            c17.7-16.7,45.6-15.8,62.3,1.9l122.8,130.7C812.2,732.2,811.3,760.1,793.6,776.7z"/>
                    </g>
                    <circle className="st1" cx="501.8" cy="483.6" r="142.7"/>
                </g>
            </svg>
        )
    }
};