import styled from "styled-components";
import {Link} from "gatsby";

export const NavBarContainer = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  width: 100%;
  background-color: #fff;
  height: 96px;
  box-shadow: 0 10px 28px rgb(0 0 0 / 10%), 0 8px 8px rgb(0 0 0 / 7%);
  z-index: 100;

  @media screen and (max-width: 1200px) {
    height: 65px;
  }
`;
export const Nav = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled.img`
  display: flex;
  height: 39px;
  @media screen and (max-width: 1200px) {
    height: 30px;
  }
`;

export const NavItems = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #183650;
  @media screen and (max-width: 1200px) {
    display: none;
  }

`;
export const NavItem = styled.div`
  font-family: 'Titillium Web', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 26px;
  cursor: pointer;
  position: relative;

  ::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: 0;
    bottom: 0px;
    border-bottom: 3px solid var(--primary-color);
    transform: scale(0, 1);
    transform-origin: 0 0;
    transition: transform 0.4s;
  }

  :hover::after {
    width: 100%;
    height: 0;
    transform: scale(1, 1);
  }

  a {
    font-family: 'Titillium Web', sans-serif;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
`;
export const NavDropdown = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left: 0;
  top: 96px;
  background: #545454;
  color: #fff;
  font-size: 13px;
  padding: 10px 0;

  a {
    padding: 9px 20px;
    width: 100%;
    justify-content: flex-start;
  }

  a:hover {
    background-color: var(--primary-color)
  }
`;
export const ResponsiveNavItems = styled.div`
  position: absolute;
  display: none;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  top: 65px;
  left: 0;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  z-index: 99;
  @media screen and (max-width: 1200px) {
    display: flex;
  }
`;

export const ResponsiveNavItem = styled(Link)`
  font-family: 'Titillium Web', sans-serif;
  padding: 16px 20px 17px;
  border-top: 1px solid #dddddd;
`;
export const ResponsiveSearchBar = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 20px;
  background-color: var(--primary-color);
  height: 60px;
  color: #fff;
  .searchInput{
    width: 350px;
    margin: 0;
    margin-left: 15px;
    padding-right: 38px;
  }
`;

export const Bars = styled.div`
  display: none;
  color: grey;

  @media screen and (max-width: 1200px) {
    display: block;
    position: absolute;
    top: -28px;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 25px;
    cursor: pointer;
  }
`;

export const ResponsiveNavDropdown = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #606163;
  color: #fff;
  font-size: 13px;
  overflow: hidden;
  max-height: 0;
  border: 0;
  transition: max-height 0.3s ease-in;

  a {
    font-family: 'Titillium Web', sans-serif;
    padding: 15px 20px 15px 50px;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
  }

  &.open {
    max-height: ${({maxHeight}) => maxHeight}px;
  }

  &.close {
    max-height: 0;

  }
`;
export const SearchDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 100%;
  position: relative;
  cursor: pointer;
  :hover{
    background-color: var(--subtitle-color);
    color: #fff;
  }
  ::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 50%;
    left: 0;
    bottom: 25%;
    border-left: 1px solid var(--primary-color);
  }
  .searchDropdown{
    width: 400px;
    left: -304px;
    padding: 30px;
  }
  .searchInput{
    margin: 0;
    padding-right: 38px;
  }
`