import styled from "styled-components";

export const DrvInput = styled.input`
    width: 100%;
    font-size: 14px;
    line-height: 17px;
    padding: 10px 16px 9px;
    border: 1px solid #e6e6e6;
    background-color: #fff;
    outline: none;
    height: auto;
    color: #a6a6a6;
    border-radius: 0;
    box-sizing: border-box;
    margin-bottom: 20px;
    &:focus{
        outline: 2px solid #0e6faa !important;
    }
    &.error{
        outline: 1px solid #f00;
    }
`