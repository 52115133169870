import * as React from "react";
import { Link } from "gatsby";

import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import {
    Container,
    FooterContainer,
    DrvLogo,
    FooterLink,
    Copyright,
    SocilaMedia,
    UpperSection,
    DownSection,
    FacebookIcon,
    LinkedinIcon,
    YouTubeIcon
} from './Footer.styles';
import Logo from '../../../images/LogoColorless.png';
import {driverCommunityIcon} from "../../../images/images";
import "./Footer.css";


const Footer = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    return(
        <Container className="flex justify-center items-center">
            <FooterContainer className="container">
                <UpperSection className="flex flex-col">
                    <Link to="/">
                        <DrvLogo src={Logo} className="ml-5 lg:ml-0" />
                    </Link>
                    <div className="flex flex-row items-center ml-5 md:m-0">
                        <MdOutlineKeyboardArrowRight className="mr-4" />
                        <FooterLink className="mr-2"
                            to="/terms-of-use">
                            Terms of Use
                        </FooterLink>
                        <MdOutlineKeyboardArrowRight className="mr-4" />
                        <FooterLink className="mr-2"
                            to="/privacy-policy">
                            Privacy Policy
                        </FooterLink>
                    </div>
                </UpperSection>
                <DownSection className="flex items-center justify-between  sm:flex-row flex-col-reverse ">
                    <Copyright>
                        Copyright © {currentYear} Drivosity LLC. All rights reserved.
                    </Copyright>
                    <SocilaMedia className="flex mb-5 md:m-0">
                            <a href='https://www.facebook.com/groups/thedeliverydriversnetwork/' className='driver-community-icon' target="_blank"
                               rel="noreferrer">
                                {driverCommunityIcon}
                            </a>
                            <a href='https://www.facebook.com/drivosity' target="_blank" rel="noreferrer">
                                <FacebookIcon/>
                            </a>
                            <a href='https://www.linkedin.com/company/drivosity-llc/' target="_blank" rel="noreferrer">
                                <LinkedinIcon/>
                            </a>
                            <a href='https://www.youtube.com/@drivosity/' target="_blank" rel="noreferrer">
                                <YouTubeIcon/>
                            </a>
                    </SocilaMedia>
                </DownSection>
            </FooterContainer>
        </Container>
)
}

export default Footer;
