import * as React from "react";

export const driverCommunityIcon = (
    <svg width="30" height="30" viewBox="0 0 335.2 408.5">
        <path className="cls-1"
              d="M153.5,7.7C124.1,27.1,61.5,52.6,20.8,60.4,8.8,62.7,0,73.2,0,85.4v166.6c0,64.4,117.1,134,155.8,153.7,7.4,3.8,16.2,3.7,23.5-.2,38.6-20.6,155.9-92.8,155.9-145.1V85.7c0-12.3-8.8-22.9-20.9-25.1-36.9-6.6-88.8-27.2-120.6-45.4-5.3-3.1-10.5-6.5-15.2-10.4-11.9-9.9-17.1-2.3-25.1,2.9h0Z"/>
        <g>
            <path className="cls-2"
                  d="M169.7,59.5c-14.1,0-24.2,9.9-24.2,24.2v18.1c0,9.7,4.7,17.3,12.1,21.2v15.1c-21.3,2.9-30.8,2.6-36.3,18.1l-18.1,60.5,32.8-28.9"/>
            <polyline className="cls-2" points="219.8 162.3 236.3 228.8 203.6 187.8"/>
            <path className="cls-2"
                  d="M169.7,59.5c14.1,0,24.2,9.9,24.2,24.2v18.1c0,9.7-4.7,17.3-12.1,21.2v15.1c7.3,1,13.3,1.6,18.2,2.7"/>
            <line className="cls-2" x1="260.5" y1="107.9" x2="54.8" y2="277.2"/>
            <path className="cls-2" d="M260.5,256.1c-18.3-11.8-48.7-21.2-90.7-21.2s-73.9,9.3-90.1,21.2"/>
            <line className="cls-2" x1="117.9" y1="324.2" x2="103.2" y2="265.1"/>
            <line className="cls-2" x1="236.2" y1="265.1" x2="221.5" y2="324.2"/>
            <path className="cls-2"
                  d="M148.8,324.2l-9.3-47.1c6.4-6.4,19.3-12.1,30.3-12.1s23.7,5.6,30.1,12.1l-9.3,47.1"/>
            <circle className="cls-2" cx="169.8" cy="187.8" r="33.8"/>
        </g>
    </svg>
);
